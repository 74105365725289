import axiosInstance from "./axiosInstance";

let api = axiosInstance()

//function to verify email
export const verifyEmail = (id, abortToken) => {
    const config = {
      ...abortToken,
      timeout: 30000, // Set the timeout for this specific request
    };
    return api.put(`/VerifyEmail/${id}`,config)
  }