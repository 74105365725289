import React, { useEffect, useState } from 'react'
import { Col, Form, Button, Row, Spinner, InputGroup, Dropdown } from 'react-bootstrap'
import { getSources } from '../../../api/SourcesApi'
import { useSignalAbort } from '../../../api/useSignalAbort'
import { createLead, getIndustry, getLeadById, getstatus, updateLead } from '../../api/leadsApi'
import { createUser, getDesignation, getUserById, getUsers, getUserType, updateUser } from '../../api/userApi'
import dayjs from 'dayjs'
import { changeFormat, successToast } from '../../../HelperFile'
import { handleApiErrors } from '../../../handleApiErrors'
import { validateForm } from '../../../handleValidation'
import { getCountry } from '../../../api/countryApi'

function UserForm({ id, isEdit, closeModal, getData }) {
    const abortConstants = useSignalAbort()
    const initialValues = {
        firstname: "",
        lastname: "",
        address: "",
        code: "",
        usertypeid: "",
        gender: "",
        email: "",
        branches: "",
        mobilenumber: "",
        username: "",
        password: "",
        designationid: 0,
        doj: "",
        bod: "",
        dor: "",
        martialstatus: "",
        createdby: 0,
        // CompanyId: loggedUser?.companyid,
        isactive: 1,
        ExpiredOn:null,
        ledgerGroup: '' ////////////////////////////////////
    }

    const validationRules = {
        Name: { required: true },
        Role: { required: true },
        Email: { required: true, isEmail: true },
        PhoneNumber: { required: true, isPhoneNumber: true },
        Username: { required: isEdit ? false : true },
        Password: { isPassword: true, required: isEdit ? false : true },
    };

    const [userData, setUserData] = useState(initialValues)
    const [formErrors, setFormerrors] = useState({})
    const [validated, setValidated] = useState(false);
    const [isSaving, setIsSaving] = useState(false)
    const [sourceSelect, setSourceSelect] = useState("")
    const [industrySelect, setIndustrySelect] = useState("");
    const [statusSelect, setStatusSelect] = useState("");
    const [userSelect, setUserSelect] = useState("");
    const [roleList, setRoleList] = useState()
    const [desigList, setDesigList] = useState()
    const [countryList, setCountryList] = useState()
    const [selectedCountry, setSelectedCountry] = useState(null);
    const [isLoading, setIsLoading] = useState({})

    const getRole = async () => {
        try {
            const result = await getUserType(abortConstants.controllerConfig)
            setRoleList(result.data.UserType)
        }
        catch (error) {
            console.log(error);
        }
    }

    const getAllDesignation = async () => {
        try {
            const result = await getDesignation(abortConstants.controllerConfig)
            setDesigList(result.data.designation)
        }
        catch (error) {
            console.log(error);
        }
    }

    //function to get country 
    const getCountryData = async () => {
        try {
            const result = await getCountry(abortConstants.controllerConfig);
            console.log(result?.data?.Country);
            setCountryList(result?.data?.Country);
            setSelectedCountry(result?.data?.Country[0])
        } catch (error) {
            handleApiErrors(error, 'Country', 'country_response', 'country_timeout', 'country_request', setIsLoading)
        }
    };

    useEffect(() => {
        getRole()
        getAllDesignation()
        getCountryData()
    }, [])

    useEffect(() => {
        if (isEdit) {
            const getUser = async (userId) => {
                try {
                    let user
                    const result = await getUserById(userId, abortConstants.controllerConfig)
                    console.log(result);
                    user = result?.data?.User
                    setUserData({
                        ...userData,
                        firstname: user?.firstname,
                        lastname: user?.lastname,
                        code: user?.code,
                        email: user?.email,
                        mobilenumber: user?.mobilenumber?.substring(1),
                        username: user?.usertypename,
                        usertypeid: user?.usertypeid,
                        designationid: user?.designationid,
                        isactive: user?.isactive
                    });
                }
                catch (error) {
                    handleApiErrors(error, 'Get User', 'getuser_response', 'getuser_timeout', 'getuser_request', setIsLoading)
                }
            }
            getUser(id)
        }
    }, [id])

    //for creating lead
    const addUser = async (data) => {
        console.log(data);
        try {
            const result = await createUser(data, abortConstants.controllerConfig)
            if (result) {
                setIsSaving(false)
                successToast(result?.data?.message)
                closeModal()
                getData()
            }
        }
        catch (error) {
            setIsSaving(false)
            handleApiErrors(error, 'Add User', 'adduser_response', 'adduser_timeout', 'adduser_request', setIsLoading)
        }
    }

    //for updating lead
    const editUser = async (id, data) => {
        console.log(data);
        try {
            const result = await updateUser(id, data, abortConstants.controllerConfig)
            console.log(result);
            if (result) {
                setIsSaving(false)
                successToast(result?.data?.message)
                closeModal()
                getData()
            }
        }
        catch (error) {
            setIsSaving(false)
            handleApiErrors(error, 'Update User', 'edituser_response', 'edituser_timeout', 'edituser_request', setIsLoading)
        }
    }

    //To set input values for form
    const handleChange = (e) => {
        // setFormerrors({})
        setUserData({ ...userData, [e.target.name]: e.target.value },)
    }

    //For form validation
    const validate = (values) => {
        const errors = validateForm(values, validationRules);
        console.log(errors);
        setFormerrors(errors)
        if (Object.keys(errors).length === 0) {
            return true;
        }
        else {
            setIsSaving(false)
            return false;
        }
    }

    //form submission
    const handleSubmit = (e) => {
        e.preventDefault()
        setIsSaving(true)
        setFormerrors({})
        console.log(userData);
        const newData ={
            Name: userData?.firstname,
            Role: userData?.usertypeid,
            Email: userData?.email,
            PhoneNumber: userData?.mobilenumber,
            Username: userData?.username,
            Password: userData?.password
        }
        if (validate(newData)) {
            if (isEdit) {
                // { ...userData, mobilenumber: "+" + selectedCountry?.CallingCode + userData.mobilenumber }
                editUser(id, userData, abortConstants.controllerConfig)
            } else {
                addUser(userData, abortConstants.controllerConfig)
            }
        }
        setValidated(true)
    }


    return (
        <div >
            <Form noValidate validated={validated} className='form leadform' onSubmit={handleSubmit}>
                <Row>
                    <Col xs={12} md={4} >
                        <Form.Group controlId='name'>
                            <Form.Label className='text-left label form-label' >First Name<span className='asterik'> *</span></Form.Label>
                            <Form.Control maxLength={100} required size='sm' type='type' name='firstname' value={userData.firstname} onChange={handleChange} />
                            <Form.Control.Feedback type="invalid">{formErrors?.Name}</Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                    <Col xs={12} md={4} >
                        <Form.Group controlId='name'>
                            <Form.Label className='text-left label form-label' >Last Name</Form.Label>
                            <Form.Control maxLength={100} size='sm' type='type' name='lastname' value={userData.lastname} onChange={handleChange} />
                            <Form.Control.Feedback type="invalid">{formErrors?.lastname}</Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                    <Col xs={12} md={4}>
                        <Form.Group controlId='phone'>
                            <Form.Label className='text-left label form-label'>Phone<span className='asterik'> *</span></Form.Label>
                            <InputGroup size='sm'>
                                <Dropdown >
                                    <Dropdown.Toggle size='sm' id="dropdown-basic" style={{ backgroundColor: '#F6F6F6', color: 'black', border: '1px solid #c4c3c3' }}>
                                        {selectedCountry && <span>
                                            <img
                                                src={`${process.env.REACT_APP_BASE_URL}${selectedCountry.CountryFlag}`}
                                                alt={selectedCountry.countryName}
                                                className="country-flag"
                                                style={{ marginRight: '5px' }}
                                            />
                                            +{selectedCountry.CallingCode}
                                        </span>}
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        {countryList?.map(item => (
                                            <Dropdown.Item key={item?.countryId} onClick={() => setSelectedCountry(item)}>
                                                <img
                                                    src={`${process.env.REACT_APP_BASE_URL}${item.CountryFlag}`}
                                                    alt={item.countryName}
                                                    className="country-flag"
                                                    style={{ marginRight: '10px' }}
                                                />
                                                +{item?.CallingCode} ({item?.countryName})
                                            </Dropdown.Item>
                                        ))}
                                    </Dropdown.Menu>
                                </Dropdown>
                                <Form.Control
                                    maxLength={selectedCountry?.MobileNumberlen}
                                    pattern="[0-9]*"
                                    required
                                    size='sm'
                                    type='tel'
                                    name='mobilenumber'
                                    value={userData.mobilenumber}
                                    onChange={handleChange}
                                    // placeholder="Phone Number"
                                    aria-label="Phone number"
                                />
                            </InputGroup>
                            <Form.Control.Feedback type="invalid" className="d-block mt-1">
                                {formErrors?.PhoneNumber} {formErrors?.CountryId}
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                </Row>
                <Row className='mt-1'>
                    <Col xs={12} md={4} >
                        <Form.Group controlId='email'>
                            <Form.Label className='text-left label form-label' >Email ID</Form.Label>
                            <Form.Control required size='sm' type='email' name='email' value={userData.email} onChange={handleChange} />
                            <Form.Control.Feedback type="invalid">{formErrors?.Email}</Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                    <Col xs={12} md={4} >
                        <Form.Group controlId="designation">
                            <Form.Label className='text-left label form-label' >Designation</Form.Label>
                            <Form.Select  size='sm' value={userData?.designationid} onChange={(e) => setUserData({...userData, designationid:Number(e.target.value)})}>
                                <option value="" disabled>Select</option>
                                {desigList?.map(item => (
                                    <option key={item.designationid} value={item.designationid}>{item.designationname}</option>
                                ))}
                            </Form.Select>
                            {/* <Form.Control.Feedback type="invalid">{formErrors?.SourceId}</Form.Control.Feedback> */}
                        </Form.Group>
                    </Col>
                    <Col xs={12} md={4} >
                        <Form.Group controlId="role">
                            <Form.Label className='text-left label form-label' >Role<span className='asterik'> *</span></Form.Label>
                            <Form.Select required size='sm' value={userData?.usertypeid} onChange={(e) => setUserData({...userData, usertypeid:Number(e.target.value)})}>
                                <option value="" disabled>Select</option>
                                {roleList?.map(item => (
                                    <option key={item.usertypeid} value={item.usertypeid}>{item.usertypename}</option>
                                ))}
                            </Form.Select>
                            <Form.Control.Feedback type="invalid">{formErrors?.Role}</Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    {/* <Col xs={12} md={4} >
                        <Form.Group controlId='company'>
                            <Form.Label className='text-left label form-label' >Company Name<span className='asterik'> *</span></Form.Label>
                            <Form.Control maxLength={120} required size='sm' type='type' name='CompanyName' value={userData.} onChange={handleChange} />
                            <Form.Control.Feedback type="invalid">{formErrors?.CompanyName}</Form.Control.Feedback>
                        </Form.Group>
                    </Col> */}
                   {!isEdit && <Col xs={12} md={4} >
                        <Form.Group controlId='username'>
                            <Form.Label className='text-left label form-label' >Username<span className='asterik'> *</span></Form.Label>
                            <Form.Control required size='sm' type='type' name='username' value={userData?.username} onChange={handleChange} />
                            <Form.Control.Feedback type="invalid">{formErrors?.Username}</Form.Control.Feedback>
                        </Form.Group>
                    </Col>}
                    {!isEdit && <Col xs={12} md={4} >
                        <Form.Group controlId='password'>
                            <Form.Label className='text-left label form-label' >Password<span className='asterik'> *</span></Form.Label>
                            <Form.Control required size='sm' type='type' name='password' value={userData?.password} onChange={handleChange} />
                            <Form.Control.Feedback type="invalid">{formErrors?.Password}</Form.Control.Feedback>
                        </Form.Group>
                    </Col>}
                    <Col xs={12} md={4} >
                        <Form.Group controlId='active'>
                            <Form.Label className='text-left label form-label' >Is Active<span className='asterik'> *</span></Form.Label>
                            <Form.Switch checked={userData?.isactive} onChange={(e)=>setUserData({...userData, isactive:e.target.checked? 1 :0})}/>
                            <Form.Control.Feedback type="invalid">{formErrors?.password}</Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                </Row>

                {/* <Row>
                    <Col xs={12} md={4}>
                        <Form.Group controlId='date'>
                            <Form.Label className='text-left label form-label' >Preferred Date<span className='asterik'> *</span></Form.Label>
                            <Form.Control required size='sm' type='date' name='PreferredDate' value={leadData.PreferredDate} onChange={handleChange} />
                            <Form.Control.Feedback type="invalid">{formErrors?.PreferredDate}</Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                    <Col xs={12} md={4} >
                        <Form.Group controlId="source">
                            <Form.Label className='text-left label form-label' >Source<span className='asterik'> *</span></Form.Label>
                            <Form.Select required size='sm' value={sourceSelect} onChange={(e) => setSourceSelect(e.target.value)}>
                                <option value="" disabled>Select</option>
                                {sourceList?.map(item => (
                                    <option key={item.SourceId} value={item.SourceId}>{item.SourceName}</option>
                                ))}
                            </Form.Select>
                            <Form.Control.Feedback type="invalid">{formErrors?.SourceId}</Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                    <Col xs={12} md={4} >
                        <Form.Group controlId="industry">
                            <Form.Label className='text-left label form-label' >Industry<span className='asterik'> *</span></Form.Label>
                            <Form.Select required size='sm' value={industrySelect} onChange={(e) => setIndustrySelect(e.target.value)}>
                                <option value="" disabled>Select</option>
                                {industryList?.map(item => (
                                    <option key={item.IndustryId} value={item.IndustryId}>{item.IndustryName}</option>
                                ))}
                            </Form.Select>
                            <Form.Control.Feedback type="invalid">{formErrors?.SourceId}</Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                </Row> */}
                {/* <Row className='mt-1'>
                    <Col xs={12} md={4}>
                        <Form.Group controlId='revenue'>
                            <Form.Label className='text-left label form-label' >Annual Revenue<span className='asterik'> *</span></Form.Label>
                            <Form.Control maxLength={20} size='sm' pattern="[0-9]*" type='number' name='AnnualRevenue' value={leadData.AnnualRevenue} onChange={handleChange} />
                            <Form.Control.Feedback type="invalid">{formErrors?.AnnualRevenue}</Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                    <Col xs={12} md={4} >
                        <Form.Group controlId="status">
                            <Form.Label className='text-left label form-label' >Lead Status<span className='asterik'> *</span></Form.Label>
                            <Form.Select required size='sm' value={statusSelect} onChange={(e) => setStatusSelect(e.target.value)}>
                                <option value="" disabled>Select</option>
                                {statusList?.map(item => (
                                    <option key={item.Id} value={item.Id}>{item.Name}</option>
                                ))}
                            </Form.Select>
                            <Form.Control.Feedback type="invalid">{formErrors?.Status}</Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                    <Col xs={12} md={4} >
                        <Form.Group controlId='reason'>
                            <Form.Label className='text-left label form-label' >Reason</Form.Label>
                            <Form.Control size='sm' type='type' name='Reason' />
                        </Form.Group>
                    </Col>
                </Row> */}
                {/* <Row className='mt-1'>
                    <Col xs={12} md={4}>
                        <Form.Group controlId="owner">
                            <Form.Label className='text-left label form-label' >Lead Owner<span className='asterik'> *</span></Form.Label>
                            <Form.Select required size='sm' value={userSelect} onChange={(e) => setUserSelect(e.target.value)}>
                                <option value="" disabled>Select</option>
                                {userList?.map(item => (
                                    <option key={item.userid} value={item.userid}>{item.firstname}</option>
                                ))}
                            </Form.Select>
                            <Form.Control.Feedback type="invalid">{formErrors?.LeadOwner}</Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                    <Col xs={12} md={8}>
                        <Form.Group className='description'>
                            <Form.Label className='text-left label form-label'>Description<span className='asterik'> *</span></Form.Label>
                            <Form.Control size='sm' className='text-left' required type='text' name='Description' value={leadData.Description} onChange={handleChange} />
                            <Form.Control.Feedback type="invalid">{formErrors?.Description}</Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                </Row> */}
                <div className='mt-3 d-flex justify-content-end' >
                    <Button
                        type={!isSaving ? 'submit' : null}
                        className={`${isSaving ? 'button-loading disable' : 'button save'}`}
                    // disabled={isSaving}
                    >
                        {isSaving ? (
                            <div className="d-flex justify-content-center align-items-center">
                                <span className='me-2'>Saving...</span>
                                <Spinner animation="border" variant="light" size='sm' />
                            </div>
                        ) : (
                            "Submit"
                        )}
                    </Button>
                    <Button className='button save mx-2' onClick={() => closeModal(false)}>
                        Cancel
                    </Button>
                </div>

            </Form>
        </div>
    )
}

export default UserForm