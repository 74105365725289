import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import { handleApiErrors } from '../../../handleApiErrors';
import { useSignalAbort } from '../../../api/useSignalAbort';
import { verifyEmail } from '../../../api/emailApi';

function EmailVerification() {
    const { id } = useParams();
    const [isLoading, setIsLoading] = useState({})
    const [emailVerify, setEmailVerify] = useState()
    const abortConstants = useSignalAbort()

    useEffect(() => {
        if (id) {
            getVerifyEmail(id)
        }
    }, [id])

    //function to get all contacts
    const getVerifyEmail = async (verify_id) => {
        try {
            const response = await verifyEmail(verify_id, abortConstants.controllerConfig)
            // console.log(response);
            setEmailVerify(response?.data)
        }
        catch (error) {
            // setIsLoading(false)
            setEmailVerify(error?.response?.data)
        }
    }

    return (
        <div className='email-verification-wrapper'>
            <div className='email-success-container'>
                <img src={emailVerify && emailVerify?.code === '1' ? process.env.PUBLIC_URL + `/assets/images/email.png` : process.env.PUBLIC_URL + `/assets/images/error.png`} alt="email" className='email_success_img mb-4 mt-2' />
                <span className='mb-4 email-success-headline'>{emailVerify?.message ? emailVerify?.message : ''}</span>
                {emailVerify && emailVerify?.code === '1' &&
                    <>
                        <span className='mb-md-2 dynamic-form-subtitle'>Thank you for confirming your email. You’re now ready to unlock the </span>
                        <span className='mb-3 dynamic-form-subtitle'>power of data-driven insights with RCOR Intelliger.</span>
                    </>
                }
            </div>
        </div>
    )
}

export default EmailVerification