// import React, { useState } from 'react'
// import { Tab, Tabs } from 'react-bootstrap'
// import CustomerForm from './CustomerForm'
// import ConnectorForm from './ConnectorForm'

// function TabSection({ id, isEdit, closeModal, getData }) {
//     const [key, setKey] = useState('customerDetails');

//     return (
//         <Tabs
//             id="controlled-tab-example"
//             activeKey={key}
//             onSelect={(k) => setKey(k)}
//             className="mb-3"
//         >
//             <Tab eventKey="customerDetails" title="Customer Details" >
//                 <CustomerForm id={id} isEdit={isEdit} closeModal={closeModal} getData={getData} />
//             </Tab>
//             <Tab eventKey="dataSource" title="Data Source">
//                 <ConnectorForm customerId={id} isEdit={isEdit} closeModal={closeModal} getData={getData}/>
//             </Tab>
//         </Tabs>
//     )
// }

// export default TabSection

import React, { useState } from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import CustomerForm from './CustomerForm';
import ConnectorForm from '../connector/ConnectorForm';

function TabSection({ id, isEdit, closeModal, getData }) {
    const [key, setKey] = useState('customerDetails');

    return (
        <Tabs
            id="controlled-tab-example"
            activeKey={key}
            onSelect={(k) => setKey(k)}
            className="mb-3"
        >
            <Tab eventKey="customerDetails" title="Customer Details">
                {key === 'customerDetails' && (
                    <CustomerForm
                        id={id}
                        isEdit={isEdit}
                        closeModal={closeModal}
                        getData={getData}
                    />
                )}
            </Tab>
            <Tab eventKey="dataSource" title="Data Source">
                {key === 'dataSource' && (
                    <ConnectorForm
                        id={id}
                        isEdit={isEdit}
                        closeModal={closeModal}
                        getData={getData}
                    />
                )}
            </Tab>
        </Tabs>
    );
}

export default TabSection;
