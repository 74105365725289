import React, { useState } from 'react';
import { useSignalAbort } from '../../../api/useSignalAbort';
import { createDatasorce, updateDatasorce } from '../../api/connectorApi';
import { handleApiErrors } from '../../../handleApiErrors';
import { toast } from 'react-toastify';
import { successToast } from '../../../HelperFile';
import { Button, Col, Row, Spinner } from 'react-bootstrap';
import { IoEye } from "react-icons/io5";
import { IoMdEyeOff } from "react-icons/io";
import TwoFactorAuthentication from './TwoFactorAuthentication';

function DynamicForm({ configSchema, selectedConnector, customerId, isEdit, closeModal, connectionName }) {
    const abortConstants = useSignalAbort()
    const [formData, setFormData] = useState(isEdit ? selectedConnector?.ConnectionData : {});
    const [isLoading, setIsLoading] = useState({})
    const [isSaving, setIsSaving] = useState(false)
    const [showPassword, setShowPassword] = useState(false)
    const [error, setError] = useState({})
    const [currentStepIndex, setCurrentStepIndex] = useState(0)
    const parsedSchema = JSON.parse(configSchema); // Parse ConfigSchema
    const currentStep = parsedSchema.steps[currentStepIndex];

    //for adding data source
    const addConnector = async (data) => {
        console.log(data);
        try {
            const result = await createDatasorce(data, abortConstants.controllerConfig)
            // console.log(result);
            if (result) {
                // successToast(result.data.message)
                closeModal(true)
            }
        }
        catch (error) {
            handleApiErrors(error, 'Create Datasource', 'addconnector_response', 'addconnector_timeout', 'addconnector_request', setIsLoading)
        }
    }

    //for updating data source
    const editConnector = async (data) => {
        console.log(data);
        try {
            const result = await updateDatasorce(selectedConnector?.ConnectionId, data, abortConstants.controllerConfig)
            // console.log(result);
            if (result) {
                // successToast(result.data.message)
                closeModal(true)
            }
        }
        catch (error) {
            handleApiErrors(error, 'Update Datasource', 'editconnector_response', 'editconnector_timeout', 'editconnector_request', setIsLoading)
        }
    }

    // Handle input changes
    const handleInputChange = (field, value) => {
        setFormData((prevData) => ({
            ...prevData,
            [field]: value,
        }));

        // Clear the error for this field on input change
        setError((prevError) => ({
            ...prevError,
            [field]: "",
        }));
    };

    const togglePasswordVisibility = (fieldName) => {
        setShowPassword((prevState) => ({
            ...prevState,
            [fieldName]: !prevState[fieldName],
        }));
    };

    const formSubmit = () => {
        const newData = {
            ConnectionName: selectedConnector[connectionName],
            DataSourceId: selectedConnector?.DataSourceId,
            CustomerId: customerId,
            ConnectionData: formData
        }

        if (isEdit) {
            editConnector({
                ...selectedConnector, ConnectionData: formData
            })
        } else {
            addConnector(newData)
        }
    }

    // Handle form submission
    // const handleSubmit = (e) => {
    //     e.preventDefault();
    //     if(parsedSchema['multi_step']){
    //         const conditionalStep = (parsedSchema['steps']?.find(item=>item.step_name ==="Login"))?.conditional_step?.value
    //         if(conditionalStep === formData.Is2FAEnabled){
    //            setIsTwoStep(true)
    //         }else{
    //             formSubmit()
    //         }
    //     }else{
    //        formSubmit()
    //     }
    // };

    // Handle form submission
    const handleSubmit = (e) => {
        e.preventDefault();
        let isValid = true;
        const newErrors = {};
        for (const field of currentStep.fields) {

            const value = formData[field.APIName] || '';

            // Check "Required" rule
            if (field.Validation.some((v) => v.RuleType === "Required") && !value) {
                newErrors[field.APIName] = field.Validation.find((v) => v.RuleType === "Required")?.ErrorMessage || `${field.DisplayLabel} is required.`;
                isValid = false;
            }

            // Check "Length" rule
            const lengthRule = field.Validation.find((v) => v.RuleType === "Length");

            if (lengthRule && value.length < parseInt(lengthRule.RuleValue, 10)) {
                // toast.error(lengthRule.ErrorMessage || `${field.DisplayLabel} exceeds maximum length of ${lengthRule.RuleValue}.`);
                isValid = false;
                newErrors[field.APIName] =
                    lengthRule.ErrorMessage || `${field.DisplayLabel} must be at least ${lengthRule.RuleValue} characters long.`;
            }
        }

        setError(newErrors);
        // If validation fails, do not proceed
        if (!isValid) {
            return;
        }

        const conditionalStep = currentStep?.conditional_step;
        if (conditionalStep) {
            // Check if the condition matches
            const { field, value, next_step } = conditionalStep;
            if (formData[field] === value) {
                setCurrentStepIndex(next_step - 1); // Navigate to the next step (adjust for 0-based index)
                return;
            }
        }

        // If no condition or condition fails, finalize the submission
        formSubmit();
    };

    return (
        // <>
        //     {!isTwoStep &&
        //         <div className="d-flex flex-column justify-content-center align-items-center p-2">
        //             {/* <span className='mb-5 dynamic-form-headline connector-form-label bold'>Connect to your {selectedConnector ? selectedConnector[connectionName] : ''} Account</span> */}
        //             <span className='mb-5 dynamic-form-headline connector-form-label bold' dangerouslySetInnerHTML={{ __html: parsedSchema['steps'][0].step_name }} />
        //             <form onSubmit={handleSubmit} className='dynamic-form-div'>
        //                 <label htmlFor={field.APIName} title={field.Tooltip}>
        //              {field.DisplayLabel}
        //          </label>

        //                 {(parsedSchema['steps']?.find(item => item.step_name === "Connect to your Linkedin Account"))?.fields?.map((field, index) => (
        //                     <div key={index} className="form-group mb-3 position-relative">
        //                         {field.FieldType === "checkbox" ? (
        //                             <div className="form-check">
        //                                 <input
        //                                     type="checkbox"
        //                                     id={field.APIName}
        //                                     name={field.APIName}
        //                                     className="form-check-input"
        //                                     checked={!!formData[field.APIName]} // Boolean value for checkboxes
        //                                     onChange={(e) =>
        //                                         handleInputChange(field.APIName, e.target.checked)
        //                                     }
        //                                     required={field.Validation.some(
        //                                         (v) => v.RuleType === "Required" && v.RuleValue === true
        //                                     )}
        //                                 />
        //                                 <label
        //                                     htmlFor={field.APIName}
        //                                     className="form-check-label"
        //                                     title={field.FieldLabel}
        //                                 >
        //                                     {field.DisplayLabel}
        //                                 </label>
        //                             </div>
        //                         ) : (
        //                             <>
        //                                 <input
        //                                     type={
        //                                         field.FieldType === "password" && showPassword[field.APIName]
        //                                             ? "text"
        //                                             : field.FieldType
        //                                     }
        //                                     id={field.APIName}
        //                                     name={field.APIName}
        //                                     placeholder={field.DisplayLabel}
        //                                     className="form-control"
        //                                     value={formData[field.APIName] || ""}
        //                                     onChange={(e) =>
        //                                         handleInputChange(field.APIName, e.target.value)
        //                                     }
        //                                     required={field.Validation.some(
        //                                         (v) => v.RuleType === "Required"
        //                                     )}
        //                                 />
        //                                 {field.FieldType === "password" && (
        //                                     <span
        //                                         className="position-absolute top-50 end-0 translate-middle-y me-3 cursor-pointer"
        //                                         onClick={() => togglePasswordVisibility(field.APIName)}
        //                                     >
        //                                         {showPassword[field.APIName] ? (
        //                                             <IoEye />
        //                                         ) : (
        //                                             <IoMdEyeOff />
        //                                         )}
        //                                     </span>
        //                                 )}
        //                             </>
        //                         )}
        //                     </div>
        //                 ))}
        //                 <Button
        //                     type={!isSaving ? 'submit' : null}
        //                     className={`w-100 ${isSaving ? 'button-loading disable' : 'button save'}`}
        //                 // disabled={isSaving}
        //                 >
        //                     {isSaving ? (
        //                         <div className="d-flex justify-content-center align-items-center">
        //                             <span className='me-2'>Saving...</span>
        //                             <Spinner animation="border" variant="light" size='sm' />
        //                         </div>
        //                     ) : (
        //                         "Submit"
        //                     )}
        //                 </Button>
        //             </form>
        //         </div>
        //     }
        //     {isTwoStep && <TwoFactorAuthentication configSchema={(parsedSchema['steps']?.find(item => item.step_name === "Two-Factor Authentication"))?.fields} formSubmit={formSubmit} />}
        // </>
        <div className="d-flex flex-column justify-content-center align-items-center p-2">
            <span className="mb-3 dynamic-form-headline connector-form-label bold" dangerouslySetInnerHTML={{ __html: currentStep.step_name }} />
            <span className='dynamic-form-subtitle mb-2' dangerouslySetInnerHTML={{ __html: currentStep.step_description.slice(0, 65) }} />
            <span className='mb-4 dynamic-form-subtitle' dangerouslySetInnerHTML={{ __html: currentStep.step_description.slice(69, 100) }} />
            <form onSubmit={handleSubmit} className="dynamic-form-div">
                {currentStep.fields.map((field, index) => {
                    const lengthRule = field.Validation.find((v) => v.RuleType === "Length");

                    return (
                        <div key={index} className="form-group mb-3 position-relative">
                            {field.FieldType === "checkbox" ? (
                                <div className="form-check">
                                    <input
                                        type="checkbox"
                                        id={field.APIName}
                                        name={field.APIName}
                                        className="form-check-input"
                                        checked={!!formData[field.APIName]}
                                        onChange={(e) => handleInputChange(field.APIName, e.target.checked)}
                                    />
                                    <label htmlFor={field.APIName} className="form-check-label">
                                        {field.DisplayLabel}
                                    </label>
                                </div>
                            ) : (
                                <>
                                    <label style={{ marginBottom: '8px', display: 'block', color: '#489C8F', fontWeight: 500 }} id={field.FieldLabel}>{field.DisplayLabel}</label>
                                    <input
                                        type={field.FieldType === "password" && showPassword[field.APIName] ? "text" : field.FieldType}
                                        id={field.APIName}
                                        name={field.APIName}
                                        placeholder={field.DisplayLabel}
                                        className="form-control"
                                        style={{ marginTop: '8px' }}
                                        value={formData[field.APIName] || ""}
                                        maxLength={lengthRule ? parseInt(lengthRule.RuleValue, 10) : undefined}
                                        onChange={(e) => handleInputChange(field.APIName, e.target.value)}
                                    />
                                    {field.FieldType === "password" && (
                                        <span
                                            className="position-absolute"
                                            style={{
                                                top: '70%',
                                                right: '10px', // Adjust spacing from the right edge of the input
                                                transform: 'translateY(-50%)',
                                                cursor: 'pointer',
                                                zIndex: 10
                                            }}
                                            onClick={() => togglePasswordVisibility(field.APIName)}
                                        >
                                            {showPassword[field.APIName] ? <IoMdEyeOff /> : <IoEye />}
                                        </span>
                                    )}
                                    {error[field.APIName] && <div className='form-error text'>{error[field.APIName]}</div>}
                                </>
                            )}
                        </div>
                    )
                })}
                <Button type="submit" className={`w-100 ${isSaving ? 'button-loading disable' : 'button save'}`}>
                    {isSaving ? (
                        <div className="d-flex justify-content-center align-items-center">
                            <span className="me-2">Saving...</span>
                            <Spinner animation="border" variant="light" size="sm" />
                        </div>
                    ) : (
                        "Submit"
                    )}
                </Button>
                {/* {currentStep?.conditional_step?.value ?
                    <div>
                        <Row>
                            <Col xs={12} md={6}>
                                <Button type="submit" variant='outline-secondary' className='w-100 button skip-button'>
                                    Skip for now
                                </Button>
                            </Col>
                            <Col xs={12} md={6}>
                                <Button type="submit" className={`w-100 ${isSaving ? 'button-loading disable' : 'button save'}`}>
                                    {isSaving ? (
                                        <div className="d-flex justify-content-center align-items-center">
                                            <span className="me-2">Saving...</span>
                                            <Spinner animation="border" variant="light" size="sm" />
                                        </div>
                                    ) : (
                                        "Submit"
                                    )}
                                </Button>
                            </Col>
                        </Row>
                    </div> :
                    <Button type="submit" className={`w-100 ${isSaving ? 'button-loading disable' : 'button save'}`}>
                        {isSaving ? (
                            <div className="d-flex justify-content-center align-items-center">
                                <span className="me-2">Saving...</span>
                                <Spinner animation="border" variant="light" size="sm" />
                            </div>
                        ) : (
                            "Submit"
                        )}
                    </Button>
                } */}

            </form>
        </div>
    );
}

export default DynamicForm;
