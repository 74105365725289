import React, { useState } from 'react'
import { Button, Col, Modal, Row } from 'react-bootstrap';
import { IoMdClose } from "react-icons/io";
import DynamicForm from './DynamicForm';
import SuccessPopup from './SuccessPopup';

function ConnectorModal({ selectedConnector, id, closePopup, isEdit, connectionName }) {
    const [modalShow, setModalShow] = useState(true);
    const [isSuccess, setIsSuccess] = useState(false)
console.log(JSON.parse(selectedConnector?.ConfigSchema));

    // Close modal and trigger closePopup
    const handleClose = () => {
        setModalShow(false);
        closePopup();
    };

    return (
        <Modal
            show={modalShow}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            backdrop="static"
        >
            <Modal.Header>
                <Row className="w-100 p-1 align-items-center" >
                    <Col xs={2} md={3} ></Col>
                    <Col xs={8} md={6} className="text-center">
                        <img src={process.env.PUBLIC_URL + `/assets/images/RCORLogo.png`} height={40} alt="Logo" className='me-4' />
                        <img src={process.env.PUBLIC_URL + `/assets/images/Link icon.png`}  alt="Logo" className='me-4' />
                        <img
                            src={`${process.env.REACT_APP_BASE_URL}${selectedConnector?.LogoURL}`}
                            className="connector-img" // Added img-fluid for responsiveness
                            alt=""
                            height={40}
                        />
                    </Col>
                    <Col xs={2} md={3} className="text-end">
                        <IoMdClose onClick={handleClose} className="common-icon" fontSize={20} />
                    </Col>
                </Row>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col xs={12}>
                        {!isSuccess ?
                            <>
                                {selectedConnector && <DynamicForm configSchema={selectedConnector?.ConfigSchema} selectedConnector={selectedConnector} customerId={id} isEdit={isEdit} closeModal={setIsSuccess} connectionName={connectionName} />}
                            </>
                            :
                            <SuccessPopup connectorLogo={selectedConnector?.LogoURL} back={handleClose}/>
                            }
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                {/* <Row>
                <span className="mb-3 dynamic-form-headline connector-form-label bold" dangerouslySetInnerHTML={{ __html: JSON.parse(selectedConnector?.ConfigSchema)?.form_footer }} />
                </Row> */}
                <Row>
                    <Col xs={1} className='d-flex justify-content-center'>
                        <img src={process.env.PUBLIC_URL + `/assets/images/Green Badge.png`} height={24} width={24} alt="Logo" />
                    </Col>
                    <Col xs={11}>
                        <span>Your login details are secure and will only be used to
                            connect to your {selectedConnector[connectionName]} account for data integration.</span>
                    </Col>
                </Row>
            </Modal.Footer>
            <Modal.Footer>
                <Row>
                    <Col xs={1} className='d-flex justify-content-center'>
                        <img src={process.env.PUBLIC_URL + `/assets/images/Green Badge.png`} height={24} width={24} alt="Logo" />
                    </Col>
                    <Col xs={11}>
                        <Row>
                            <Col xs={12}>
                                <span>Your security is our priority. We follow top industry
                                    standards to keep your data safe. Here are our certifications
                                    ensuring secure integration.</span>
                            </Col>
                        </Row>
                        <Row className='mt-4 mb-3'>
                            <Col xs={6} md={2}>
                                <img src={process.env.PUBLIC_URL + `/assets/images/GDPA.png`} height={50} alt="Logo" />
                            </Col>
                            <Col xs={6} md={3}>
                                <img src={process.env.PUBLIC_URL + `/assets/images/HIPAA.png`} height={50} alt="Logo" />
                            </Col>
                            <Col xs={6} md={3}>
                                <img src={process.env.PUBLIC_URL + `/assets/images/ISO 27001-2022.png`} height={50} alt="Logo" />
                            </Col>
                            <Col xs={6} md={2}>
                                <img src={process.env.PUBLIC_URL + `/assets/images/ISO 27001-2013.png`} height={50} alt="Logo" />
                            </Col>
                            <Col xs={6} md={2}>
                                <img src={process.env.PUBLIC_URL + `/assets/images/SOC 2.png`} height={50} alt="Logo" />
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Modal.Footer>
        </Modal>
    )
}

export default ConnectorModal