import React, { useEffect, useState } from 'react'
import { Button, Card, Col, Form, Modal, Row } from 'react-bootstrap'
import { getCategory, getConnectors } from '../../api/connectorApi';
import { handleApiErrors } from '../../../handleApiErrors'
import { useSignalAbort } from '../../../api/useSignalAbort';
import DynamicForm from './DynamicForm';
import ConnectorModal from './ConnectorModal';

function Connectors({ customerId }) {
    const abortConstants = useSignalAbort()
    const [categories, setCategories] = useState()
    const [connectors, setConnectors] = useState()
    const [selectedCategory, setSelectedCategory] = useState()
    const [selectedConnector, setSelectedConnector] = useState()
    const [isLoading, setIsLoading] = useState({})
    const [modalShow, setModalShow] = useState(false);

    useEffect(() => {
        getAllCategory()
    }, [])

    //connectors
    const getAllCategory = async () => {
        try {
            const result = await getCategory(abortConstants.controllerConfig);
            // console.log(result);
            setCategories(result?.data?.DataSourceType)
            setSelectedCategory(result?.data?.DataSourceType[0]?.DataSourceTypeId)
            getConnector(result?.data?.DataSourceType[0]?.DataSourceTypeId)
        }
        catch (error) {
            console.log(error);
            handleApiErrors(error, 'Connectors', 'connetor_response', 'connetor_timeout', 'connetor_request', setIsLoading)
        }
    };

    //connectors
    const getConnector = async (id) => {
        try {
            const result = await getConnectors(id, abortConstants.controllerConfig);
            // console.log(result);
            setConnectors(result?.data?.DataSource)
        }
        catch (error) {
            console.log(error);
            handleApiErrors(error, 'Connectors', 'connetor_response', 'connetor_timeout', 'connetor_request', setIsLoading)
        }
    };

    const setCategory = (id) => {
        setSelectedCategory(id)
        getConnector(id)
    }

    const getConnectorForm = (connector) => {
        setSelectedConnector(connector)
        setModalShow(true)
    }

    const closePopup = () => {
        setModalShow(false)
        // getConnector(selectedConnector?.DataSourceId)
    }

    return (
        <>{!modalShow ?
            <div>
                <Row className='mt-3'>
                    <Col xs={12} md={3} className='col-rightBorder'>
                        <span className='connector-form-label data-source heading'>Category</span>
                        <div className="mt-2">
                            {categories && categories?.length > 0 ? (
                                categories.map((category, index) => (
                                    <div key={index} className={`connector-form-label ${category.DataSourceTypeId === selectedCategory ? 'connector-form-label-color' : ''} common-icon mb-1`} onClick={() => setCategory(category.DataSourceTypeId)}>
                                        {category.DataSourceTypeName}
                                    </div>
                                ))
                            ) : (
                                <div>No categories available</div>
                            )}
                        </div>
                    </Col>
                    {selectedCategory && <Col xs={12} md={9}>
                        <Row>
                            {connectors && connectors?.length > 0 ? (
                                connectors.map((connector, index) => (
                                    <Col key={index} xs={6} md={3} className='mt-2'>
                                        <div className='connector-card connector-card-border common-icon' onClick={() => getConnectorForm(connector)}>
                                            <img
                                                src={`${process.env.REACT_APP_BASE_URL}${connector.LogoURL}`}
                                                className="connector-img" // Added img-fluid for responsiveness
                                                alt=""
                                            />
                                            <span className='connector-form-label'>{connector.DataSourceName}</span>
                                            {/* <Form.Check // prettier-ignore
                                            type='checkbox'
                                            id='default-checkbox'
                                        /> */}
                                        </div>
                                    </Col>
                                ))
                            ) : (
                                <div>No connectors available</div>
                            )}
                        </Row>
                    </Col>}
                </Row>
            </div>
            :
            <ConnectorModal selectedConnector={selectedConnector} id={customerId} closePopup={closePopup} connectionName='DataSourceName' isEdit={false}/>
        }</>
    )
}

export default Connectors