// import React, { useEffect, useState } from 'react'
// import { Table, Row, Col, Spinner } from 'react-bootstrap';
// import LeadForm from './LeadForm';
// import CrudActions from '../../components/CrudActions';
// import FollowupForm from './FollowupForm';
// import { useSignalAbort } from '../../../api/useSignalAbort';
// import { handleApiErrors } from '../../../handleApiErrors';
// import { deleteLead, getLead } from '../../api/leadsApi';
// import { useSelector } from 'react-redux';
// import DetailForm from './DetailForm';
// import { renderLoading, successToast } from '../../../HelperFile';

// function Leads() {
//   const abortConstants = useSignalAbort()
//   const user = useSelector(state => state.adminUser)
//   const menu = useSelector(state => state.userMenu)
//   // const operations = menu.filter(item => item.subMenu_name === "Branches" && item.Action_CurdNo !==1 && item.Action_CurdNo !==2)
//   // console.log(operations);
//   const [leads, setLeads] = useState()
//   const [isLoading, setIsLoading] = useState({})

//   //function to get all leads
//   const getAllLeads = async () => {
//     try {
//       const response = await getLead(abortConstants.controllerConfig)
//       setLeads(response?.data?.Leads)
//     }
//     catch (error) {
//       handleApiErrors(error, 'Lead', 'lead_response', 'lead_timeout', 'lead_request', setIsLoading)
//     }
//   }

//   useEffect(() => {
//     getAllLeads()
//   }, [])

//   //for deleteing lead
//   const deleteLeads = async (id) => {
//     console.log(id);
//     try {
//       const result = await deleteLead(id, abortConstants.controllerConfig)
//       console.log(result);
//       if (result) {
//         successToast(result?.data?.message)
//         getAllLeads()
//       }
//     }
//     catch (error) {
//     handleApiErrors(error, 'Delete', 'delete_response', 'delete_timeout', 'delete_request', setIsLoading)
//     }
//   }

//   return (

//     <div>
//       <Row>
//         <Col xs={6}>
//           <h5>Leads Management</h5>
//         </Col>
//         <Col xs={6} className="d-flex justify-content-end">
//           {/* Create icon */}
//           <CrudActions crudNo={1} form={LeadForm} title='New Lead' isEdit={false} getData={getAllLeads} />
//         </Col>
//       </Row>
//       <Row className='mt-3'>
//         <Col xs={12}>
//           {leads ?
//             <>
//               {leads.length !== 0 ?
//                 <Table striped bordered hover>
//                   <thead>
//                     <tr >
//                       <th className='table-style table-heading'>Name</th>
//                       <th className='table-style table-heading'>Company Name</th>
//                       <th className='table-style table-heading'>Industry</th>
//                       <th className='table-style table-heading'>Phone</th>
//                       <th className='table-style table-heading'>Mail</th>
//                       <th className='table-style table-heading'>Lead Date</th>
//                       <th className='table-style table-heading'>Source</th>
//                       <th className='table-style table-heading'>Status</th>
//                       <th className='table-style table-heading'>Actions</th>
//                       {/* <th className='table-style table-heading'>Followup</th> */}
//                     </tr>
//                   </thead>
//                   <tbody>
//                     {leads?.map((item, index) => (
//                       <tr key={index}>
//                         <td className='table-style table-font'>{item.Name}</td>
//                         <td className='table-style table-font'>{item.CompanyName}</td>
//                         <td className='table-style table-font'>{item.IndustryName}</td>
//                         <td className='table-style table-font'>{item.PhoneNumber}</td>
//                         <td className='table-style table-font'>{item.EmailId}</td>
//                         <td className='table-style table-font'>{(new Date(item.PreferredDate)).toISOString().split('T')[0]}</td>
//                         <td className='table-style table-font'>{item.SourceName}</td>
//                         <td className='table-style table-font'>{item.StatusName}</td>
//                         <td className='table-style table-font'>
//                           <CrudActions id={item.LeadsId} crudNo={2} form={LeadForm} title='Edit Lead' isEdit={true} getData={getAllLeads}  />
//                           <CrudActions id={item.LeadsId} crudNo={5}  getData={getAllLeads} deleteData={deleteLeads}/>
//                           <CrudActions id={item.LeadsId} crudNo={4} form={DetailForm} title='' isEdit={true} getData={getAllLeads} />
//                         </td>
//                         {/* <td className='table-style table-font'>
//                           <CrudActions crudNo={3} form={FollowupForm} title='Followups' isEdit={false} getData={getAllLeads} />
//                         </td> */}
//                       </tr>
//                     ))}
//                   </tbody>

//                   <tbody>

//                   </tbody>
//                 </Table>
//                 :
//                 <h4 style={{ textAlign: 'center' }}>No data</h4>
//               }
//             </>

//             :
//             <div className='d-flex justify-content-center mt-5'>
//               {renderLoading(isLoading, 'lead') ?
//                 <span>No data</span>
//                 : <Spinner animation="border" />
//               }
//             </div>}
//         </Col>
//       </Row>
//     </div>



//   )
// }

// export default Leads

// import React, { useEffect, useState } from 'react';
// import { Table, Row, Col, Spinner, Pagination } from 'react-bootstrap';
// import LeadForm from './LeadForm';
// import CrudActions from '../../components/CrudActions';
// import FollowupForm from './FollowupForm';
// import { useSignalAbort } from '../../../api/useSignalAbort';
// import { handleApiErrors } from '../../../handleApiErrors';
// import { deleteLead, getLead } from '../../api/leadsApi';
// import { useSelector } from 'react-redux';
// import DetailForm from './DetailForm';
// import { convertDateForDisplay, renderLoading, successToast } from '../../../HelperFile';

// function Leads() {
//   const abortConstants = useSignalAbort();
//   const user = useSelector(state => state.adminUser);
//   const menu = useSelector(state => state.userMenu);
//   const [leads, setLeads] = useState([]);
//   const [isLoading, setIsLoading] = useState(false);
//   const [currentPage, setCurrentPage] = useState(1);
//   const leadsPerPage = 13; // Adjust this number to set how many leads you want per page

//   // Function to get all leads
//   const getAllLeads = async () => {
//     setIsLoading(true);
//     try {
//       const response = await getLead(abortConstants.controllerConfig);
//       console.log(response);
//       setLeads(response?.data?.Leads);
//       setIsLoading(false);
//     } catch (error) {
//       handleApiErrors(error, 'Lead', 'lead_response', 'lead_timeout', 'lead_request', setIsLoading);
//     }
//   };

//   useEffect(() => {
//     getAllLeads();
//   }, []);

//   // Function for deleting lead
//   const deleteLeads = async (id) => {
//     try {
//       const result = await deleteLead(id, abortConstants.controllerConfig);
//       if (result) {
//         successToast(result?.data?.message);
//         getAllLeads();
//       }
//     } catch (error) {
//       handleApiErrors(error, 'Delete', 'delete_response', 'delete_timeout', 'delete_request', setIsLoading);
//     }
//   };

//   // Get current leads
//   const indexOfLastLead = currentPage * leadsPerPage;
//   const indexOfFirstLead = indexOfLastLead - leadsPerPage;
//   const currentLeads = leads?.slice(indexOfFirstLead, indexOfLastLead);

//   // Change page
//   const nextPage = () => {
//     setCurrentPage(prevPage => Math.min(prevPage + 1, Math.ceil(leads.length / leadsPerPage)));
//   };

//   const prevPage = () => {
//     setCurrentPage(prevPage => Math.max(prevPage - 1, 1));
//   };

//   return (
//     <div>
//       <Row>
//         <Col xs={6}>
//           <h5>Leads Management</h5>
//         </Col>
//         <Col xs={6} className="d-flex justify-content-end">
//           <CrudActions crudNo={1} form={LeadForm} title="New Lead" isEdit={false} getData={getAllLeads} />
//         </Col>
//       </Row>
//       <Row className="mt-3">
//         <Col xs={12}>
//           {isLoading ? (
//             <div className="d-flex justify-content-center mt-5">
//               <Spinner animation="border" />
//             </div>
//           ) : leads.length > 0 ? (
//             <>
//               <Table striped bordered hover>
//                 <thead>
//                   <tr>
//                     <th className="table-style table-heading">Name</th>
//                     <th className="table-style table-heading">Company Name </th>
//                     <th className="table-style table-heading">Industry</th>
//                     <th className="table-style table-heading">Phone</th>
//                     <th className="table-style table-heading">Mail</th>
//                     <th className="table-style table-heading">Lead Date</th>
//                     <th className="table-style table-heading">Source</th>
//                     <th className="table-style table-heading">Status</th>
//                     <th className="table-style table-heading">Actions</th>
//                   </tr>
//                 </thead>
//                 <tbody>
//                   {currentLeads.map((item, index) => (
//                     <tr key={index}>
//                       <td className="table-style table-font">{item.Name}</td>
//                       <td className="table-style table-font">{item.CompanyName}</td>
//                       <td className="table-style table-font">{item.IndustryName}</td>
//                       <td className="table-style table-font">{item.PhoneNumber}</td>
//                       <td className="table-style table-font">{item.EmailId}</td>
//                       <td className="table-style table-font">{convertDateForDisplay(item.CreatedDate)}</td>
//                       <td className="table-style table-font">{item.SourceName}</td>
//                       <td className="table-style table-font">{item.StatusName}</td>
//                       <td className="table-style table-font">
//                         <CrudActions id={item.LeadsId} crudNo={2} form={LeadForm} title="Edit Lead" isEdit={true} getData={getAllLeads} />
//                         <CrudActions id={item.LeadsId} crudNo={5} getData={getAllLeads} deleteData={deleteLeads} />
//                         <CrudActions id={item.LeadsId} crudNo={4} form={DetailForm} title="" isEdit={true} getData={getAllLeads} />
//                       </td>
//                     </tr>
//                   ))}
//                 </tbody>
//               </Table>
//               {/* <Pagination>
//                 {[...Array(Math.ceil(leads.length / leadsPerPage)).keys()].map(number => (
//                   <Pagination.Item key={number + 1} active={number + 1 === currentPage} onClick={() => paginate(number + 1)}>
//                     {number + 1}
//                   </Pagination.Item>
//                 ))}
//               </Pagination> */}
//               <Pagination className="justify-content-end">
//                 <Pagination.Prev onClick={prevPage} disabled={currentPage === 1} />
//                 <Pagination.Item>{currentPage}</Pagination.Item>
//                 <Pagination.Next onClick={nextPage} disabled={currentPage === Math.ceil(leads.length / leadsPerPage)} />
//               </Pagination>
//             </>
//           ) : (
//             <h4 style={{ textAlign: 'center' }}>No data</h4>
//           )}
//         </Col>
//       </Row>
//     </div>
//   );
// }

// export default Leads;


import React, { useEffect, useState } from 'react';
import { Table, Row, Col, Spinner, Pagination, Button } from 'react-bootstrap';
import LeadForm from './LeadForm';
import CrudActions from '../../components/CrudActions';
import FollowupForm from './FollowupForm';
import { useSignalAbort } from '../../../api/useSignalAbort';
import { handleApiErrors } from '../../../handleApiErrors';
import { deleteLead, getLead, sendVerifyEmail } from '../../api/leadsApi';
import { useSelector } from 'react-redux';
import DetailForm from './DetailForm';
import { convertDateForDisplay, renderLoading, successToast } from '../../../HelperFile';
import Filter from '../../components/Filter';

const initialFilterValues = {
  Name: '',
  // CompanyName: '',
  // IndustryName: '',
  // PhoneNumber: '',
  // EmailId: '',
  Date: '',
  // SourceName: '',
  Status: ''
}
function Leads() {
  const abortConstants = useSignalAbort();
  const user = useSelector(state => state.adminUser);
  const menu = useSelector(state => state.userMenu);
  const [leads, setLeads] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const leadsPerPage = 13; // Adjust this number to set how many leads you want per page
  const [filters, setFilters] = useState(initialFilterValues);

  // Function to get all leads
  const getAllLeads = async () => {
    setIsLoading(true);
    try {
      const response = await getLead(abortConstants.controllerConfig);
      const updatedData = response?.data?.Leads.sort((a, b) => new Date(b.CreatedDate) - new Date(a.CreatedDate))
      setLeads(updatedData);
      setIsLoading(false);
    } catch (error) {
      handleApiErrors(error, 'Lead', 'lead_response', 'lead_timeout', 'lead_request', setIsLoading);
    }
  };

  useEffect(() => {
    getAllLeads();
  }, []);

  // Function for deleting lead
  const deleteLeads = async (id) => {
    try {
      const result = await deleteLead(id, abortConstants.controllerConfig);
      if (result) {
        successToast(result?.data?.message);
        getAllLeads();
      }
    } catch (error) {
      handleApiErrors(error, 'Delete', 'delete_response', 'delete_timeout', 'delete_request', setIsLoading);
    }
  };

  // Function for deleting lead
  const sendVerifyEmailLink = async (id) => {
    try {
      const result = await sendVerifyEmail(id, abortConstants.controllerConfig);
      console.log(result);
      // getAllLeads()
      if (result) {
        successToast(result?.data?.message);
      }
    } catch (error) {
      handleApiErrors(error, 'Send  Link', 'sendlink_response', 'sendlink_timeout', 'sendlink_request', setIsLoading);
    }
  };


  const handleFilterChange = (e, columnName) => {
    setFilters({
      ...filters,
      [columnName]: e.target.value
    });
  };

  const clearFilter = (columnName) => {
    setFilters({
      ...filters,
      [columnName]: ''
    });
  };

  const getFilteredLeads = () => {
    return leads.filter(lead => {
      // const leadDate = new Date(lead.CreatedDate).toISOString().split('T')[0];
      return (
        (filters.Name === '' || lead.Name.toLowerCase().includes(filters.Name.toLowerCase())) &&
        // (filters.CompanyName === '' || lead.CompanyName.toLowerCase().includes(filters.CompanyName.toLowerCase())) &&
        // (filters.IndustryName === '' || lead.IndustryName.toLowerCase().includes(filters.IndustryName.toLowerCase())) &&
        // (filters.PhoneNumber === '' || lead.PhoneNumber.includes(filters.PhoneNumber)) &&
        // (filters.EmailId === '' || lead.EmailId.toLowerCase().includes(filters.EmailId.toLowerCase())) &&
        (filters.Date === '' || convertDateForDisplay(lead.CreatedDate) === convertDateForDisplay(filters.Date)) &&
        // (filters.SourceName === '' || lead.SourceName.toLowerCase().includes(filters.SourceName.toLowerCase())) &&
        (filters.Status === '' || lead.StatusName.toLowerCase().includes(filters.Status.toLowerCase()))
      );
    });
  };

  // Get current leads
  const indexOfLastLead = currentPage * leadsPerPage;
  const indexOfFirstLead = indexOfLastLead - leadsPerPage;
  const currentLeads = getFilteredLeads().slice(indexOfFirstLead, indexOfLastLead);

  // Change page
  const nextPage = () => {
    setCurrentPage(prevPage => Math.min(prevPage + 1, Math.ceil(leads.length / leadsPerPage)));
  };

  const prevPage = () => {
    setCurrentPage(prevPage => Math.max(prevPage - 1, 1));
  };

  return (
    <div>
      <Row>
        <Col xs={6}>
          <h5>Leads Management</h5>
        </Col>
        <Col xs={6} className="d-flex justify-content-end">
          <CrudActions crudNo={1} form={LeadForm} title="New Lead" isEdit={false} getData={getAllLeads} />
        </Col>
      </Row>
      <Row className="mt-3">
        <Col xs={12}>
          {isLoading ? (
            <div className="d-flex justify-content-center mt-5">
              <Spinner animation="border" />
            </div>
          ) : leads.length > 0 ? (
            <>
              <Table striped bordered hover>
                <thead>
                  <tr>
                    {/* <th className="table-style table-heading d-flex">Name
                      <Filter columnName="Name" handleFilterChange={handleFilterChange} />
                    </th> */}
                    <th className="table-style table-heading">Company Name</th>
                    <th className="table-style table-heading">Industry</th>
                    <th className="table-style table-heading">Phone</th>
                    <th className="table-style table-heading">Mail</th>
                    <th className="table-style table-heading">Lead Date
                      <Filter columnName="Date" handleFilterChange={handleFilterChange} />
                    </th>
                    <th className="table-style table-heading">Source</th>
                    <th className="table-style table-heading">Status
                      <Filter columnName="Status" handleFilterChange={handleFilterChange} />
                    </th>
                    <th className="table-style table-heading">Promo Code</th>
                    <th className="table-style table-heading">Email Status</th>
                    <th className="table-style table-heading">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {currentLeads.map((item, index) => (
                    <tr key={index}>
                      {/* <td className="table-style table-font">{item.Name}</td> */}
                      <td className="table-style table-font">{item.CompanyName}</td>
                      <td className="table-style table-font">{item.IndustryName}</td>
                      <td className="table-style table-font">{item.PhoneNumber}</td>
                      <td className="table-style table-font">{item.EmailId}</td>
                      <td className="table-style table-font">{convertDateForDisplay(item.CreatedDate)}</td>
                      <td className="table-style table-font">{item.SourceName}</td>
                      <td className="table-style table-font">{item.StatusName}</td>
                      <td className="table-style table-font">{item.PromoCode}</td>
                      <td className="table-style table-font">
                        {item.IsEmailVerified === 1 ? 'Verified' : ''}
                        {item.IsEmailVerified !== 1 &&
                         <Button variant="link" className='table-style table-font' onClick={()=>sendVerifyEmailLink(item.LeadsId)}>Verify</Button>
                        }
                      </td>
                      <td className="table-style table-font">
                        <CrudActions id={item.LeadsId} crudNo={2} form={LeadForm} title="Edit Lead" isEdit={true} getData={getAllLeads} />
                        <CrudActions id={item.LeadsId} crudNo={5} getData={getAllLeads} deleteData={deleteLeads} />
                        <CrudActions id={item.LeadsId} crudNo={4} form={DetailForm} title="" isEdit={true} getData={getAllLeads} isVerified={item.IsEmailVerified}/>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
              <Pagination className="justify-content-end">
                <Pagination.Prev onClick={prevPage} disabled={currentPage === 1} />
                <Pagination.Item>{currentPage}</Pagination.Item>
                <Pagination.Next onClick={nextPage} disabled={currentPage === Math.ceil(leads.length / leadsPerPage)} />
              </Pagination>
            </>
          ) : (
            <h4 style={{ textAlign: 'center' }}>No data</h4>
          )}
        </Col>
      </Row>
    </div>
  );
}

export default Leads;
