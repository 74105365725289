import React from 'react'
import { Button } from 'react-bootstrap'

function SuccessPopup({ connectorLogo, back }) {
    return (
        <div className="d-flex flex-column justify-content-center align-items-center p-2 ">
            <span className='dynamic-form-headline connector-form-label bold mb-3'>You have now allowed RCOR to connect to your accounts :</span>
            <img
                src={`${process.env.REACT_APP_BASE_URL}${connectorLogo}`}
                className="connector-img mb-3" // Added img-fluid for responsiveness
                alt=""
                height={40}
            />
            <span className='dynamic-form-headline mb-3'>Hang tight while we work our magic!</span>
            <Button className='button save successpopup-button' onClick={()=>back()}>Done</Button>
        </div>
    )
}

export default SuccessPopup