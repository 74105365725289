import React, { useEffect, useState } from 'react'
import { Button, Card, Col, Form, Modal, Row } from 'react-bootstrap'
import { deleteDatasource, getCategory, getConnectors, getDatasorces, getDatasorcesById, sendStatus } from '../../api/connectorApi';
import { handleApiErrors } from '../../../handleApiErrors'
import { useSignalAbort } from '../../../api/useSignalAbort';
import Connectors from './Connectors';
import DynamicForm from './DynamicForm';
import { successToast } from '../../../HelperFile';
import ConnectorModal from './ConnectorModal';

function ConnectorForm({ id, closeModal }) {
    const abortConstants = useSignalAbort()
    const [isConnectors, setIsConnectors] = useState(false)
    const [datasorces, setDatasorces] = useState()
    const [datasourceDetails, setDatasourceDetails] = useState()
    const [selectedDatasource, setSelectedDatasource] = useState()
    const [isLoading, setIsLoading] = useState({})
    const [modalShow, setModalShow] = useState(false);

    useEffect(() => {
        if (id) {
            getAllDataSource(id);
        }
    }, [id]);

    //connectors
    const getAllDataSource = async (id) => {
        try {
            const result = await getDatasorces(id, abortConstants.controllerConfig);
            console.log(result);
            setDatasorces(result?.data?.DataSourceConnection)
            setSelectedDatasource(result?.data?.DataSourceConnection[0])
            getDatasourceById(result?.data?.DataSourceConnection[0]?.ConnectionId)
        }
        catch (error) {
            console.log(error);
            handleApiErrors(error, 'Connectors', 'connetor_response', 'connetor_timeout', 'connetor_request', setIsLoading)
        }
    };

    //connectors
    const getDatasourceById = async (id) => {
        try {
            const result = await getDatasorcesById(id, abortConstants.controllerConfig);
            console.log(result);
            setDatasourceDetails(result?.data?.DataSourceConnection)
        }
        catch (error) {
            console.log(error);
            handleApiErrors(error, 'Data Souce By Id', 'datasourcebyid_response', 'datasourcebyid_timeout', 'datasourcebyid_request', setIsLoading)
        }
    };

    // Function for deleting lead
    const removeDatasource = async (connectionId) => {
        try {
            const result = await deleteDatasource(connectionId, abortConstants.controllerConfig);
            if (result) {
                successToast(result?.data?.message);
                setDatasourceDetails(null)
                getAllDataSource(id);
            }
        } catch (error) {
            handleApiErrors(error, 'Delete', 'delete_response', 'delete_timeout', 'delete_request', setIsLoading);
        }
    };

    // Function for update status
    const sendDatasourceStatus = async (data) => {
        console.log(data);       
        try {
            const result = await sendStatus(data, abortConstants.controllerConfig);
            console.log(result);

            if (result) {
                successToast(result?.data?.message);
            }
        } catch (error) {
            handleApiErrors(error, 'Send Status', 'status_response', 'status_timeout', 'status_request', setIsLoading);
        }
    };

    const setDatasource = (datasource) => {
        setSelectedDatasource(datasource)
        getDatasourceById(datasource.ConnectionId)
    }

    const closePopup = () => {
        setModalShow(false)
        getDatasourceById(datasourceDetails?.ConnectionId)
    }

    const refreshData = () => {
        if (isConnectors) {
            setDatasourceDetails(null)
            getAllDataSource(id)
        }
        setIsConnectors(!isConnectors)
    }

    const deleteConfirmation = (id) => {
        const confirmation = window.confirm("Are you sure you want to delete?");
        if (confirmation) {
            removeDatasource(id)
        }
    };

    const datasourceStatus = (status) => {
        sendDatasourceStatus({
            CustomerId: id,
            DataSourceId: datasourceDetails?.DataSourceId,
            ConnectionId: datasourceDetails?.ConnectionId,
            Stage: status
        })
    };

    return (
        <>{!modalShow ?
            <div className='form leadform'>
                <Row>
                    <Col xs={12} md={8}>
                        <div className='data-source heading'>{isConnectors ? 'Select Connectors' : 'Data Sources'}</div>
                        <div className='data-source subtitle'>
                            {isConnectors ? 'Choose your preferred data integration app' : 'Data source imports set up in this workspace'}
                        </div>
                    </Col>
                    <Col xs={12} md={4} className='d-flex justify-content-end'>
                        <Button className='button datasource-add' onClick={() => refreshData()}>
                            {isConnectors ? 'Back' : 'Add Data Source'}
                        </Button>
                    </Col>
                </Row>
                {!isConnectors && <Row className='mt-3'>
                    <Col xs={12} md={3} className='col-rightBorder p-0 ps-1'>
                        <div className="mt-2">
                            {datasorces && datasorces?.length > 0 ? (
                                datasorces.map((datasource, index) => (
                                    <div key={index} className={`w-100 d-flex flex-column data-source subtitle mb-1 p-1 ps-4 ${datasource.ConnectionId === selectedDatasource?.ConnectionId ? 'connector-card-border data-source div connector-form-label-color' : ''} common-icon`} onClick={() => setDatasource(datasource)}>
                                        <span>{datasource.ConnectionName}</span>
                                        {/* <span>ABC School Pvt Ltd</span> */}
                                    </div>
                                ))
                            ) : (
                                <div>No data sources available</div>
                            )}
                        </div>
                    </Col>
                    {selectedDatasource &&
                        <Col xs={12} md={9} className='p-3'>
                            {datasourceDetails ? (
                                <>
                                    <Row className='data-source-bottom-border pb-3'>
                                        <Col xs={12}>
                                            <div>{datasourceDetails.ConnectionName}</div>
                                            {/* <div className='data-source-span data-source-span-color'>Synchronization status</div> */}
                                        </Col>
                                    </Row>
                                    <Row className='pt-2'>
                                        <Col xs={6} md={4} className='d-flex flex-column data-source subtitle'>
                                            {/* <span className='data-source-span'>Profile Name</span> */}
                                            <span className='data-source-span'>Category</span>
                                            <span className='data-source-span'>Actions</span>
                                        </Col>
                                        <Col xs={6} md={8} className='d-flex flex-column data-source subtitle'>
                                            {/* <span className='data-source-span'>: ABC School Pvt Ltd</span> */}
                                            <span className='data-source-span'>: {datasourceDetails.DataSourceTypeName}</span>
                                            <span className='data-source-span'>:
                                                <span className='common-icon data-source-operation' onClick={() => setModalShow(true)}>Update</span>
                                                &nbsp;&nbsp;&nbsp;
                                                <span className='common-icon data-source-operation' onClick={() => deleteConfirmation(datasourceDetails?.ConnectionId)}>Delete</span>
                                                &nbsp;&nbsp;&nbsp;
                                                <span className='common-icon data-source-operation' onClick={() => datasourceStatus('Success')}>Success</span>
                                                &nbsp;&nbsp;&nbsp;
                                                <span className='common-icon data-source-operation' onClick={() => datasourceStatus('Incorrect')}>Incorrect</span>
                                                &nbsp;&nbsp;&nbsp;
                                                <span className='common-icon data-source-operation' onClick={() => datasourceStatus('OTP')}>OTP</span>
                                            </span>
                                        </Col>
                                    </Row>
                                </>
                            ) : (
                                <div>No data available</div>
                            )}
                        </Col>
                    }
                </Row>}
                {isConnectors && <Connectors customerId={id} />}
                <div className='mt-3 d-flex justify-content-end' >
                    <Button className='button save mx-2' onClick={() => closeModal(false)}>
                        Cancel
                    </Button>
                </div>
            </div>
            :
            <ConnectorModal selectedConnector={datasourceDetails} id={id} closePopup={closePopup} isEdit={true} connectionName='ConnectionName' />
        }</>
    )
}

export default ConnectorForm