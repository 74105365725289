import React, { useState } from 'react'
import { FaEdit, FaTrash, FaPlus } from 'react-icons/fa';
import { TiMediaFastForwardOutline } from "react-icons/ti";
import { BiDetail } from "react-icons/bi";
import { MdMovieEdit } from "react-icons/md";
import ModalPopup from './ModalPopup';

function CrudActions({ crudNo, form, title, isEdit, id, getData, deleteData, isVerified }) {
    const [modalShow, setModalShow] = useState(false);
    const FormComponent = form

    const deleteConfirmation = () => {
        const confirmation = window.confirm("Are you sure you want to delete?");
        if (confirmation) {
            deleteData(id)
        } 
    };
    return (
        <>
            {crudNo === 1 && <FaPlus className="create-icon" onClick={() => setModalShow(true)} />}
            {crudNo === 2 && <FaEdit className='edit-icon' onClick={() => setModalShow(true)} />}
            {crudNo === 3 && <TiMediaFastForwardOutline onClick={() => setModalShow(true)} />}
            {crudNo === 4 && <BiDetail className='mx-3 create-icon' style={{fontSize:'14px'}} onClick={() => setModalShow(true)} />}
            {crudNo === 5 && <FaTrash  className='delete-icon' onClick={deleteConfirmation}/>}
            {crudNo === 6 && <img src={process.env.PUBLIC_URL + `/assets/images/data source icon.png`} height={16}  alt="icon" className='common-icon' onClick={() => setModalShow(true)} />}

            <ModalPopup show={modalShow} title={title} onHide={() => setModalShow(false)} form={FormComponent?<FormComponent id={id} isEdit={isEdit} closeModal={setModalShow} getData={getData} isVerified={isVerified} />:null} />
        </>
    )
}

export default CrudActions